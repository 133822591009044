import React, { forwardRef, useMemo, memo } from 'react';

import graphics from '@core/graphics';
import useClassy from '@core/hooks/useClassy';
import useUniqueId from '@core/hooks/useUniqueId';

import { AsyncGif, AsyncSvg } from './AsyncGraphic';
import classes from './style.module.scss';

const graphicRemapping = {
  'c++': 'cplusplus',
  'c#': 'csharp',
};

export interface GraphicProps extends React.HTMLAttributes<HTMLElement> {
  /**
   * Optional class name to tack onto the SVG element.
   */
  className?: string;

  /**
   * Graphic name, e.g. `amazon`
   */
  name: string;

  /**
   * Scale the graphic by preset sizes or some number of pixels.
   */
  size?: number | 'lg' | 'md' | 'sm';

  /* Optional style object */
  style?: React.CSSProperties & {
    '--Graphic-size'?: string;
  };

  /**
   * Includes an SVG "title" that describes the icon for better a11y. By
   * default, the icon name is used but can otherwise be overrided.
   */
  title?: string;

  /**
   * Indicates the asset type to be loaded. Uses `svg` by default.
   */
  type?: 'gif' | 'svg';

  /**
   * Optional class name to tack onto the graphic <span>.
   */
  wrapperClassName?: string;
}

const Graphic = memo(
  forwardRef<HTMLElement, GraphicProps>(
    ({ className, name, size, title, style, type = 'svg', wrapperClassName, ...attrs }, ref) => {
      const bem = useClassy(classes, 'Graphic');
      const uid = useUniqueId('Graphic');

      // Some graphics file names do always match what people refer to them as so we need to see if we
      // have a remapping set for it.
      // eslint-disable-next-line no-param-reassign
      name = name in graphicRemapping ? graphicRemapping[name as keyof typeof graphicRemapping] : name;

      const styles = useMemo(() => {
        return {
          ...style,
          '--Graphic-size': size ? (Number(size) ? `${size}px` : `var(--icon-${size})`) : undefined,
        };
      }, [size, style]);

      // Async props are sent to our loadable components to create dynamic imports.
      const loadableProps = useMemo(
        () => ({
          'data-name': name,
        }),
        [name],
      );

      // Render our async SVGR icon with a fallback.
      return (
        <span ref={ref} className={bem('&-wrapper', wrapperClassName)}>
          {type === 'svg' ? (
            <AsyncSvg
              className={bem('&', '-svg', className)}
              fallback={<span className={bem('&', '-empty', className)} style={styles} />}
              role="img"
              style={styles}
              title={title}
              titleId={uid(name)}
              {...attrs}
              {...loadableProps}
            />
          ) : (
            <AsyncGif {...loadableProps}>
              {({ default: data }: { default: string }) => (
                <img
                  alt={title}
                  className={bem('&', '-img', className)}
                  src={data}
                  style={styles}
                  {...attrs}
                  {...loadableProps}
                />
              )}
            </AsyncGif>
          )}
        </span>
      );
    },
  ),
);

export default Graphic;

/**
 * Determine if a given graphic exists within our dataset.
 *
 */
export function doesGraphicExist(graphic: string | false) {
  if (!graphic) {
    return false;
  }

  const name = graphic.toLowerCase();
  return Object.keys(graphics).includes(
    name in graphicRemapping ? graphicRemapping[name as keyof typeof graphicRemapping] : name,
  );
}
