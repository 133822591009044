import type { TippyProps } from '@tippyjs/react';

import Tippy from '@tippyjs/react';
import React, { useMemo } from 'react';

import classy from '@core/utils/classy';

import './style.scss';

export interface TooltipProps extends Omit<TippyProps, 'singleton'> {
  /**
   * Tooltip designed to replace HTML [title]
   */
  asTitle?: boolean;

  /**
   * Tooltip content to show when reference element is triggered.
   */
  content: React.ReactNode;

  /**
   * When a `string`, references the TooltipSingleton by `id` as its singleton
   * source. Otherwise, expects a SingletonObject `source` or `target` returned
   * from [`useSingleton`](https://github.com/atomiks/tippyjs-react#-usesingleton).
   */
  singleton?: TippyProps['singleton'] | string;
}

/**
 * Trigger Tippy on any element by wrapping it in this tooltip component. You
 * can pass plain-text or rich JSX to the tooltip using the `content` prop.
 */
const Tooltip = ({ children, content, className, singleton, asTitle, ...props }: TooltipProps) => {
  const classes = useMemo(() => classy('rm-Tooltip', className), [className]);
  const trigger = typeof children === 'string' ? <span className="tippy-text">{children}</span> : children;

  interface ITitleProps {
    arrow: boolean;
    delay: [number, number];
    offset: [number, number];
  }

  // title props
  const titleProps: ITitleProps = {
    arrow: false,
    delay: [500, 0],
    offset: [0, 5],
  };

  // See if singleton exists
  const singletonTarget = typeof singleton === 'string' ? Tooltip.singletons[singleton] : singleton;

  // Accessibility Note: This version of tippy (4.1.0) requires you to pass aria={{ content: 'describedBy' }} for screen
  // readers to read the tooltip content.
  return (
    <Tippy
      className={`${classes} ${asTitle ? 'rm-Tooltip_title' : ''}`}
      content={content}
      duration={150}
      placement="bottom-start"
      singleton={singletonTarget}
      zIndex={99999}
      {...props}
      {...(asTitle && titleProps)}
    >
      {trigger as React.ReactElement}
    </Tippy>
  );
};

/**
 * Hash of singleton target objects by an ID. New entries are created whenever
 * new TooltipSingleton instances are created. Targets are then reference-able
 * based on an ID string.
 */
Tooltip.singletons = {} as Record<string, NonNullable<TippyProps['singleton']>>;

export default Tooltip;
export { default as TooltipSingleton } from './Singleton';
export { useSingleton } from '@tippyjs/react';
